@font-face {
  font-family: museo sans rounded;
  src: url("../src/SharedComponents/MuseoSansRounded-300.otf")
}


body {
  margin: 0;
  padding: 0;
  font-family: Lato, sans-serif;
  /*font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",*/
    /*"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",*/
    /*sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*opacity: 0.3*/
}

.thinHeading{
  font-weight: 300;
}

.team-guide-box-style{
  border: 1px solid lightgrey;
  border-radius: 10px;
  padding: 10px;
}

.team-guide-box-style > h5{
  text-align: center;
  font-weight: bold;
}
.FileNameSearchInputBox{
  color:yellow;
}
.iconHeading{
  padding-left: 10px;
}
#dbfields{
  margin-bottom: 15px;
}

#dbfields > label{
  font-weight: bold;
}


.heroSection{
  font-family: museo sans rounded, sans-serif;
  padding-top: 2%;
  padding-bottom: 4%;
}

.updatedLandingHero{
  margin-top: 40px;
  display: grid;
  grid-template-columns: 48% 52%;
  font-family: museo sans rounded, sans-serif;
}

.updatedLandingHero h1{

}

.updatedLandingHeroMobile{
  margin-top: 80px;
  display: grid;
  grid-template-columns: 100%;
  font-family: museo sans rounded, sans-serif;
}

.updatedLandingHeroMobile img {
  display: none;
}

.servingGlobalAPIHeader{
  font-family: museo sans rounded, sans-serif;
  font-size: 350%;
}

.landingPageSectionHeader{
  font-family: museo sans rounded, sans-serif;
  font-size: 300%;
  font-weight: 800;
}

a.nostyle:link, a.nostyle:visited {
  text-decoration: inherit;
  color: inherit;
}

.fileTabBackgroundHover:hover{
  background-color: #E9FCE9;
}

.btnHover{
  cursor: pointer;
  transition: transform .2s; /* Animation */
}

.btnHover:hover{
  transform: scale(1.1);
}

.signUpHeroButton{
  border: 0;
  background: none;
  box-shadow: none;
  cursor: pointer;
  background-color: #52bd95;
  border-radius: 15px;
  /*margin-top: 40%;*/
  /*margin-top: 100px;*/
  /*width: 325px;*/
  /*height: 115px;*/
  /*font-size: 34px;*/
  width: 225px;
  height: 80px;
  font-size: 24px;
  /*margin-left: 10%;*/
  /*float: right;*/
  color: white;
  font-weight: 800;
  font-family: museo sans rounded, sans-serif;
}

.updatedLandingHeroMobile .signUpHeroButton{
  text-align: center;
}

.updatedLandingHeroMobile h1{
  text-align: center;
}

.signUpHeroButton:hover{
  box-shadow: 0 8px 32px 0 rgba(0,0,0,0.2);
}

.hoverShadow:hover{
  box-shadow: 0 8px 32px 0 rgba(0,0,0,0.2);
}

.updatedLandingSocialProof{
  padding: 140px 100px 0 100px;
}

.customerTestimonials{
  width: 50%;
  margin: 0 auto;
  background-color: whitesmoke;
  text-align: center;
  padding: 30px 0 30px 0;
  border-radius: 25px;
  box-shadow: 0 8px 32px 0 rgba(0,0,0,0.2);
  margin-bottom: 50px;
}

.customerTestimonialLink > h4{
  color: black;
}




@media only screen and (min-width: 600px) {
  .heroDiv{
    background: url("../src/SharedComponents/BannerBackground.svg") no-repeat;
    background-size: 100% auto;
  }
}

@media only screen and (max-width: 600px) {
  .heroDiv{
    /*background: url("../src/SharedComponents/BannerBackground.svg") no-repeat;*/
    /*background-size: 100% auto;*/
    background: linear-gradient(90deg, rgba(177,198,14,1) 0%, rgba(0,212,255,1) 100%);
  }
}


.heroDiv > div{
  padding-top: 75px;
  padding-bottom: 75px;
  text-align: center;
  font-family: museo sans rounded, sans-serif;
}

.heroDiv h1{
  font-size: 350%;
}

.uploadOuterSection{
  border: 2px dashed #DEDEDE;
  /*background-color: #99e099;*/
  color: white;
  /*border-radius: 5px;*/
  min-height: 250px;
  /*padding-top: 1%;*/
  /*padding-left: 1%;*/
  /*padding: 5px;*/
  /*display: flex;*/
  /*flex-direction: column;*/
  /*justify-content: center;*/
  /*resize: vertical;*/
  /*overflow: auto;*/
  /*margin-bottom: 2.5%;*/
  cursor: pointer;
}

.uploadInnerSection{
  /*border: 1px dashed #7F7F7F;*/
  border: 1px dashed rgba(0, 0, 0, 0.15);
  /*border: 2px dashed whitesmoke;*/
  /*background-color: #009900;*/
  background-color: #008e00;
  height: 100%;
  width: 100%;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /*resize: vertical;*/
  /*overflow: auto;*/
}

.uploadLabel{
  /*height: 100%;*/
  /*width: 100%;*/
  text-align: center;
  padding: 10px 23px;
  min-width: 120px;
  height: 40px;
  /*background: #6FBB98;*/
  border-radius: 4px;
  font-size: 14px;
  font-weight: 700;
  /*color: #FFFFFF;*/
  font-family: 'Lato', sans-serif;
  cursor: pointer;
}

.uploadLabelOrange{
  /*background: #E27837;*/
}

.uploadIcons{
  font-size: 300%;
  font-weight: 300;
}

.lightgray{
  background-color: lightgrey;
}

.uploadProgressBar{
  width: 50%;
  text-align: center;
  margin: 0 auto;
}

.codeFileURL{
  margin-top: 16px;
  border: 1px solid #F0F0F0;
  border-radius: 4px;
  padding: 16px;
}

.codeFileURL button{
  margin-left: 10px;
}

.customImportFileURL{
  background-color: #203354;
  color: lightgrey;
  padding: 10px;
  font-size: 14px;
  margin-top: 10px;
  border-radius: 4px;
}

.customImportFileURL h5{
  margin: 0;
  font-size: 14px;
}

.customImportFileURL button{
  margin-top: -10px;
}

.fileDropTarget{
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  resize: both;
  margin-bottom: 2%;
}

.fileDropSource{
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid white;
  border-radius: 5px;
  background-color: white;
  height: 90%;
  width: 90%;
}

.fileDropSourceDrag{
  visibility: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid white;
  border-radius: 5px;
  background-color: white;
  height: 90%;
  width: 90%;
  -ms-transform: rotate(20deg); /* IE 9 */
  -webkit-transform: rotate(20deg); /* Safari */
  transform: rotate(20deg);
}

.linkStyle{
  color: cornflowerblue;
  cursor: pointer;
}

.columnNamesInfoBodyRow{
  display: grid;
  grid-template-columns: 37.5% 62.5%;
  padding: 12px 14px;
}

.linkStyle:hover{
  text-underline: blue;
  color: blue;
}

.app{
  /*display: flex;*/
  min-height: 100vh;
  /*flex-direction: column;*/
}

.roundedButton{
  border-radius: 40px;
}

.downloadFileIndex{
  /*background-color: #ffdb99;*/
  /*border: 1px solid #ffdb99;*/
  border-radius: 5px;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: auto auto auto;
  grid-template-areas:
          "header header"
          "fileName removeDuplicates"
          "downloadButton downloadButton";
}

.mergedFileNameDiv{
  grid-area: fileName;
  justify-self: end;
  margin-right: 10%;
  align-self: center;
}

.removeDuplicatesDiv{
  grid-area: removeDuplicates;
  margin-left: 5%;
  align-self: center;
  font-size: 120%;
}

.downloadButtonDiv{
  margin-top: 25px;
  margin-bottom: 25px;
  grid-area: downloadButton;
}

.downloadHeader{
  padding-top: 12px;
  padding-bottom: 20px;
  text-align: center;
  grid-area: header;
}

.editOptionDiv{
  margin-bottom: 15px;
}

.importSheetOptions{
  /*padding-top: 10px;*/
  /*padding-bottom: 10px;*/
}

.wordwrap{
  word-break: break-all;
  word-wrap: break-word;
}

.wordwrap2{
  white-space: pre-wrap;      /* CSS3 */
  white-space: -moz-pre-wrap; /* Firefox */
  /*white-space: -pre-wrap;     !* Opera <7 *!*/
  white-space: -o-pre-wrap;   /* Opera 7 */
  word-wrap: break-word;      /* IE */
}

.ellipses{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.semanticBorder{
  margin: 0em;
  max-width: 100%;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  outline: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.28571429rem;
  -webkit-transition: border-color 0.1s ease, -webkit-box-shadow 0.1s ease;
  transition: border-color 0.1s ease, -webkit-box-shadow 0.1s ease;
  transition: box-shadow 0.1s ease, border-color 0.1s ease;
  transition: box-shadow 0.1s ease, border-color 0.1s ease, -webkit-box-shadow 0.1s ease;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: rgba(0, 0, 0, 0.8);
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 5px;
}

.focusBorder{
  border-color: #85B7D9;
  background: #FFFFFF;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.pricingPage{
  display: grid;
  grid-template-columns: 33% 33% 34%;
  grid-template-rows: auto;
  grid-template-areas:
          "freePricingCard paidPricingCard businessPricingCard";
  min-height: 100vh;
  grid-column-gap: 25px;
}

.freePricingCard{
  grid-area: freePricingCard;
}

.paidPricingCard{
  grid-area: paidPricingCard;
  margin: 0 auto;

}

.businessPricingCard{
  grid-area: businessPricingCard;
}

.pricingCard{
  /*padding-left: 30%;*/
  /*padding-right: 30%;*/
  padding-top: 10%;
}

.desc {
  list-style-type: none;
  border: 1px solid #eee;
  margin: 0;
  padding: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.desc li {
  border-bottom: 1px solid #eee;
  padding: 10px;
  text-align: center;
}

.closeIconClass{
  height: 25px;
  width: 25px;
  float: right;
  margin: 0 auto;
  cursor: pointer;
  text-align: center;
}

.closeIconClass:hover{
  background-color: #DCDCDC;
  border-radius: 50%;
}

.closeIconClass > i{
  padding: 0;
}

#radioOutputFormatGroup{
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 200px 200px auto;
  grid-column-gap: 5px;
  grid-template-areas: "jsonRow matrix jsonColumn";
}

.jsonRowRadioDiv{
  grid-area: jsonRow;
}

.matrixRadioDiv{
  grid-area: matrix;
}

.jsonColumnRadioDiv{
  grid-area: jsonColumn;
}

#privateDiv{
  text-align: center;
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: auto auto;
  grid-template-areas: "privateCheckbox privateKey";
}

#privateCheckboxDiv{
  grid-area: privateCheckbox;
}

#privateKeyDiv{
  grid-area: privateKey;
}

.footerShareGrid{
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  grid-template-rows: auto;
  grid-template-areas: "twitter facebook reddit linkedin email";
}

.socialMediaButton{
  cursor: pointer;
}
.footerEmptySpace{
  grid-area: footerEmpty;
}
#twitterButton{
  grid-area: twitter;
}

#facebookButton{
  grid-area: facebook;
}

#redditButton{
  grid-area: reddit;
}

#linkedinButton{
  grid-area: linkedin;
}

#emailButton{
  grid-area: email;
}

.switchAPIType{
  display: grid;
  grid-template-columns: 100px auto;
  grid-template-rows: auto;
  grid-template-areas:
          "getData postData";
  grid-column-gap: 10px;
}

.getAPIType{
  grid-area: getData;
}

.postAPIType{
  grid-area: postData;
}

.docsPageIndex{
  min-height: 100vh;
  display: grid;
  grid-template-columns: 20% 80%;
  grid-template-rows: auto;
  grid-template-areas:
          "docsMenu docsDisplay";
}

.docsMenu{
  grid-area: docsMenu;
  border-right: 1px solid lightgray;
  /*background-color: #DCDCDC;*/
  padding-top: 30px;
}

.docsDisplay{
  grid-area: docsDisplay;
  padding-top: 30px;
  padding-left: 50px;
  padding-right: 50px;
}

.urlBox{
  border: 1px solid lightgray;
  border-radius: 5px;
  width: 100%;
  background-color: #203354;
  color: white;
  padding: 10px;
}

.baseURLBox{
  border: 1px solid lightgray;
  border-radius: 5px;
  width: 100%;
  background-color: #203354;
  color: white;
  /*padding: 7px;*/
}

.fileDesc input{
  background-color: #203354;
  color: white;
  border: none;
  width: 340px;
  font-family: Consolas,monospace;

}

.urlBoxLanding{
  border: 1px solid lightgray;
  border-radius: 5px;
  width: 100%;
  background-color: #ccccff;
  padding: 10px;
}

.parameterName{
  font-weight: 800;
}

.parameterType{
  font-size: 90%;
  color: gray;
  padding-left: 5px;
}

.parameterSecondLine{
  /*margin-top: -15px;*/
  font-size: 80%;
  display: block;
}

.parameterRequired{
  color: red;
}

.parameterDesc{
  margin-top: -10px;
}

#parameterHeading{
  margin-bottom: -10px;
  margin-top: 20px;
  font-size: 14px;
  font-weight: 700;
}

.parameterDefault{
  font-style: italic;
}

.getParam{
  padding-right: 10px;
}

.getResponse{
  padding-left: 30px;
}

.getResponseHeading{
  border: 1px solid gray;
  border-radius: 5px;
  background-color: #E8E8E8;
  padding: 10px;
  color: black;
  padding-bottom: 30px;

}

.jsonDisplayBoxHeader{
  border: 1px solid gray;
  border-radius: 5px 5px 0 0;
  background-color: #E8E8E8;
  padding: 10px;
  color: black;
}

.getResponseBox{
  width: 350px;
  border: 1px solid gray;
  border-radius: 5px;
  margin-top: 5px;
}

.getResponseBoxLanding{
  width: 100%;
  border: 1px solid gray;
  border-radius: 5px;
  margin-top: 5px;
}

#getResponseCodesHeading{
  margin-bottom: -10px;
  margin-top: 20px;
  font-size: 14px;
  font-weight: 700;
}

.getResponseCodes{
  padding: 0 10px;
}

.responseCodeStatus{
  font-size: 105%;
  background-color: #f5f5f5;
  padding: 5px;
  font-weight: 800;
}

.responseCodeParam{
  font-size: 90%;
}

.codeSuccess{
  color: green;
}

.responseCodeDesc{
  display: block;
  margin-top: 1px;
  padding-left: 5px;
  font-size: 85%;
}

.responseCodeLine{
  margin-top: -5px;
  margin-bottom: -5px;
}

.codeFail{
  color: red;
}

#guidesIndex{
  min-height: 100vh;
  /*padding: 0 100px 240px 100px;*/
  /*padding*/
}

#contactGuide{
  padding: 8px;
  border: 1px dashed gray;
  border-radius: 5px;
}

#formCode{
  width: 95%;
  margin-bottom: 40px;
}

.dropbtn {
  border-radius: 5px;
  background-color: white;
  color: black;
  padding: 10px 8px 10px 8px;
  font-size: 14px;
  border: none;
  cursor: pointer;
  width: 100%;
}

.customDropdown {
  position: relative;
  display: inline-block;
  width: 100%;
  border-radius: 5px;
  border: 1px solid lightgrey;
  /*box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);*/
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  border-radius: 0 0 5px 5px;
  min-width: 160px;
  width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 999;
}

.dropdown-content div {
  color: black;
  padding: 6px 8px;
  text-decoration: none;
  display: block;
}

.dropdown-content div:hover {background-color: #f1f1f1}

.customDropdown:hover .dropdown-content {
  display: block;
}

.customDropdown:hover .dropbtn {
  background-color: whitesmoke;
  border-radius: 5px 5px 0 0;
}

.segment{
  background: #fff;
  border-radius: 2px;
  padding: 20px;
}

.segment-raised, .segment-raised-hover{
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}

.segment-raised-hover:hover{
  box-shadow: 0 20px 40px rgba(0,0,0,0.38), 0 12px 12px rgba(0,0,0,0.46);
}

.segment-margin{
  background: #fff;
  border-radius: 2px;
  margin: 1rem;
  padding: 20px;
}

.segment-no-padding{
  background: #fff;
  border-radius: 2px;
}

.create-team-form-fields{
  margin-bottom: 16px;
}

.create-team-form-fields > label{
  /*font-weight: bold;*/
}

.row-hover:hover{
  background-color: whitesmoke;
}

.free-pricing-column{
  background-color: lightblue;
  border-radius: 5px;
}

.pricingFeaturesDiv{
  /*padding-top: 25px;*/
  font-size: 125%;
}

.pricingGrid{
  padding-left: 100px;
  padding-right: 100px;
  padding-top: 80px;
  text-align: center;
}

.mobilePricingGrid{
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 50px;
  text-align: center;
}

.subheading{
  color: gray;
}

.pricingHeader, .pricingCost{
  margin-bottom: -5px;
}

.pricingCost{
  margin-top: 10px;
}

.pricingCTA{
  font-size: 14px;
  font-weight: 700;
}

.pricingBox{
  padding-top: 30px;
  padding-bottom: 30px;
}

.proPricingBox{
  border-radius: 10px;
  background-color: whitesmoke;
}

.teamPricingBox{
  padding-top: 0px;
  background-color: #203354;
  border-radius: 10px;
  color: white;
}

.customPricingBox{
  background-color: black;
  color: white;
  border-radius: 10px;
}

.pricingBestValueTag{
  background-color: orange;
  color: white;
  height: 25px;
  margin-bottom: 5px;
  padding-top: 3px;
  border-radius: 10px 10px 0 0;
  font-weight: bold;
}

.featureItem{
  font-size: 125%;
}



.avatar {
  vertical-align: middle;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.faqSummary{
  border-bottom: 1px dotted;
  cursor: pointer;
  /*float: left;*/
  /*text-align: left;*/
}

.pricingDots{
  margin-top: 100px;
}

.pricingFAQs{
  /*margin-top: 50px;*/
  margin-bottom: 200px;
}

.pricingFAQDiv h5{
  /*padding-left: 20px;*/
  /*padding-right: 10px;*/
  font-weight: 600;
  font-size: 16px;
  color: #161E16;
  padding-bottom: 16px;
}

@media only screen and (max-width: 600px){
  .pricingFAQDiv h5{
    /*padding-left: 20px;*/
    /*padding-right: 10px;*/
    font-weight: 600;
    font-size: 16px;
    color: #161E16;
    padding-bottom: 8px;
  }

  .pricingFAQDiv{
    padding-bottom: 16px;
  }
}

.inputLabel{
  font-size: 115%;
  font-weight: bold;
}

.guidesDisplay{
  padding-bottom: 250px;
  /*width: 90%;*/
}

.guidePost p{
  font-size: 125%;
  margin-bottom: 40px;
}

.guidePost li{
  font-size: 125%;
  margin-bottom: 10px;
}

.guidePost figure{
  margin-bottom: 40px;
}

.bold{
  font-weight: bold;
}

/*.guidePost blockquote{*/
  /*background: #f9f9f9;*/
  /*border-left: 10px solid #ccc;*/
  /*margin: 1.5em 10px;*/
  /*padding: 0.5em 10px;*/
/*}*/

/*.guidePost blockquote:before {*/
  /*color: #ccc;*/
  /*font-size: 4em;*/
  /*line-height: 0.1em;*/
  /*margin-right: 0.25em;*/
  /*vertical-align: -0.4em;*/
/*}*/

/*.guidePost > blockquote p {*/
  /*display: inline;*/
  /*font-size: 130%;*/
  /*font-style: italic;*/
/*}*/

.tableHeaderDesc{
  color: green;
  font-weight: 300;
}

#columnInfoSaveBox{
  border-radius: 6px 6px 0px 0px;
  background-color: #F9F9F9;
  text-align: right;
  border-bottom: 1px solid #F0F0F0;
  /*background-color: whitesmoke;*/
  /*border-radius: 5px;*/
  padding: 10px;
  /*margin-bottom: 20px;*/
}

#nameColDescSub, #descColDescSub, #typeColDescSub{
  font-size: 105%;
  color: gray;
  font-weight: 500;
}

#nameColDescSub{

}

#descColDescSub{

}

#typeColDescSub{

}

.guidesMenu{
  border: 1px solid lightgrey;
  border-radius: 5px;
  padding: 10px;
}

.guidesMenuDesc{
  color: gray;
}

.codeTheoryBox{
  border: 1px lightgrey solid;
  border-radius: 4px;
  padding: 12px;
  background-color: whitesmoke;

}

.codeExampleBox{
  border: 1px #6FBB98 solid;
  border-radius: 4px;
  padding: 12px;
  background-color: rgba(209, 228, 206, 0.3);
}

.codeExampleBox > b{
  color: green;
  display: block;
}

.codeInvalidBox > b{
  color: red;
  display: block;
}

.codeInvalidBox{
  border: 1px red solid;
  border-radius: 4px;
  padding: 12px;
  background-color: #ffd8d8;
}

.replaceText{
  font-size: 250%;
  color: red;
  display: block;
}

.fileIDBox{
  border: 1px slategray solid;
  border-radius: 5px;
  padding: 5px;
  background-color: whitesmoke;
  /*box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);*/
  /*height: 75px;*/
}

.fileNameBox{
  /*height: 75px;*/
}

.privateButtonBg{
  background-color: white;
  border-radius: 5px;
  border: 1px lightgrey solid;
  padding: 10px 5px 5px 5px;
  font-weight: bold;
}

.fileSummaryActionTypeRadio{
  padding-right: 15px;
  padding-left: 5px;
  padding-top: 2px;
  cursor: pointer;
}

input[type="radio"]{
  cursor: pointer;
}

.fileSummaryCodeToolbar{
  margin-top: 16px;
  margin-bottom: 16px;
  border: 1px solid #F0F0F0;
  border-radius: 4px;
  padding: 16px;
  font-size: 14px;
  font-family: Lato, sans-serif;
  /*border: 1px solid lightgrey;*/
  /*border-radius: 10px;*/
  /*padding: 10px 10px 10px 2px;*/
  /*height: 40px;*/
}

.boxOutline{
  font-size: 14px;
  font-family: Lato, sans-serif;
  padding: 16px;
  border-radius: 4px;
  border: 1px solid #F0F0F0;
}

.queryQuickstartRulesDiv{
  border: 1px solid lightgrey;
  border-radius: 5px;
  padding: 10px;
}

.jsonDisplayBox{
  background-color: lightgrey;
  border: 1px lightgrey solid;
  border-radius: 5px 5px 0 0;
  padding: 10px;
}

.clickDiv{
  cursor: pointer;
  border: 1px solid lightgrey;
  text-align: center;
  padding: 10px;
  border-radius: 10px;
}

.clickDiv:hover{
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.fileDescDiv{
  /*display: grid;*/
  /*grid-template-columns: 85% auto;*/
  /*grid-template-rows: auto;*/
  /*grid-template-areas: "descTextArea descButtons";*/
  /*padding: 5px;*/
}

.fileDescTextArea{
  grid-area: descTextArea;
}

.fileDescTextArea textarea{
  /*height: 30px;*/
}

.fileDescTextArea p{
  /*background-color: whitesmoke;*/
}

.fileDescButtons{
  text-align: right;
}

.fileDesc{
  /*background-color: #203354;*/
  /*border-radius: 5px;*/
  /*margin-left: 2px;*/
}

.shadowDiv{
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.customAccordion{
  border: 1px solid #F0F0F0;
  background-color: white;
}

.customAccordionHeader{
  cursor: pointer;
  width: 100%;
  text-align: left;
  outline: none;
  border: none;
}

.customAccordionHeader:focus {outline:0;}


.customAccordionHeaderText{
  grid-area: accordionHeaderText;
  padding-left: 15px;
}

.customAccordionHeaderIcon{
  grid-area: accordionHeaderIcon;
  text-align: center;
}


.customAccordionBody{
  padding: 0 15px 15px 15px;
}

.quickstartAccordionPrompt{
  color: green;
  float: right;
  /*font-weight: 300;*/
  display: block;
  font-style: italic;
  font-size: 75%;
  padding-top: 5px;
}

.privateButtonOpaque{
  opacity: 0.3;
  cursor: none;
}



.privateButtonUnlock{
  float: right;
  cursor: pointer;
  color: green;
  opacity: 1;
}


.react-calendar {
  width: 187px;
  max-width: 100%;
  background: red;
  border: none;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  height: 44px;
  margin-bottom: 1em;
}
.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}
.react-calendar__month-view__weekNumbers {
  font-weight: bold;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}
.react-calendar__month-view__days__day--weekend {
  color: #d10000;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.react-calendar__tile {
  max-width: 100%;
  text-align: center;
  padding: 0.75em 0.5em;
  background: none;
}
.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__tile--hasActive {
  background: #76baff;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}
.react-calendar__tile--active {
  background: yellow;
  color: white;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #1087ff;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}


.customImage{
  cursor: pointer;
  /*margin-top: 0px;*/
  margin-bottom: 30px;
}

.startModalRow{
  text-align: center;
}

.startModalRow span {
  font-weight: bold;
  font-size: 120%;
}


.footerIndex{
  /*background-color: #00AE00;*/
  /*background-color: #52bd95;*/
}

.fullFooterBox h3{
  padding-bottom: 15px;
  font-weight: 300;
}

.fullFooter{
  border-top: 1px solid #F0F0F0;
  margin: 0 100px 0 100px;
  padding: 50px 10px 10px 10px;
  color: #161E16;
  font-family: Lato, sans-serif;
}

.apiSocialFooterBox img{
  padding-top: 20px;
  padding-bottom: 20px;
  float: left;
}


.apiSocialFooterBox span{
  float: left;
  margin-bottom: 15px;
}

.footerItem{
  cursor: pointer;
  text-decoration: none;
  display: block;
  margin-bottom: 12px;
  color: #161E16;
  font-family: Lato, sans-serif;
  font-weight: 400;
  font-size: 14px;
}

.footerItem:hover{
  cursor: pointer;
  color: #5499F8;
  text-decoration: none;
}

#feedbackDiv{
  width: 250px;
  font-size: 90%;
  background-color: whitesmoke;
}

.feedbackButtons{
  margin-top: 10px;
}

.feedbackResponseMessage{
  padding-top: 10px;
  width: 250px;
  text-align: center;
  font-size: 75%;
}

.helpIndex{
  min-height: 100vh;
}

.helpViewIndex {
  padding: 50px;
  margin-bottom: 250px;
}


#pricingPopupBody > table {
  border-collapse: collapse;
  /*border: 1px solid lightgrey;*/
}

#pricingPopupBodyEvenRow td, #pricingPopupBodyOddRow td{
  border-left: 1px solid lightgrey;
  border-right: 1px solid lightgrey;
  text-align: center;
}

#pricingPopupBodyEvenRow > td:nth-child(3), #pricingPopupBodyOddRow > td:nth-child(3){
  background-color: whitesmoke;
}
#pricingPopupBodyEvenRow > td:nth-child(4), #pricingPopupBodyOddRow > td:nth-child(4){
  background-color: #203354;
  color: white;
}
#pricingPopupBodyEvenRow > td:nth-child(5), #pricingPopupBodyOddRow > td:nth-child(5){
   background-color: black;
   color: white;
}

#pricingPopupBodyEvenRow{
  background-color: whitesmoke;
}

/*#pricingPopupBodyEvenRow, #pricingPopupBodyOddRow{*/
  /*border: 1px solid lightgrey;*/
/*}*/

#pricingPopupBodyCTA > td{
  padding: 5px;
  margin-top: 5px;
  border: 1px solid lightgrey;
  border-radius: 5px;
  text-align: center;
}

#pricingPopupBodyHeader, #pricingPopupBodyPricing{
  text-align: center;
}

#pricingPopupBodyPricing > td{
  border-left: 1px solid lightgrey;
  border-bottom: 1px solid lightgrey;
  border-right: 1px solid lightgrey;
  padding: 0 5px 5px 5px;
}

#pricingPopupBodyHeader > th{
  border-left: 1px solid lightgrey;
  border-top: 1px solid lightgrey;
  border-right: 1px solid lightgrey;
  padding: 5px 5px 0 5px;
  /*border-radius: 5px 5px 0 0;*/
}

#pricingPopupBodyHeader > th:nth-child(3), #pricingPopupBodyPricing > td:nth-child(3){
  background-color: whitesmoke;
}

#pricingPopupBodyHeader > th:nth-child(4), #pricingPopupBodyPricing > td:nth-child(4){
  background-color: #203354;
  color: white;
}

#pricingPopupBodyHeader > th:nth-child(5), #pricingPopupBodyPricing > td:nth-child(5){
  background-color: black;
  color: white;
}

.faqEmailUs{
  text-align: center;
  margin-bottom: 200px;
}

#navbarHelpLinks{
  padding-top: 10px;
  width: 100px;
}

#navbarHelpLinks div{
  margin-bottom: 20px;
}

.supportFormLabel{
  font-size: 110%;
}

.requiredLabel{
  padding-left: 3px;
  padding-right: 3px;
  color: red;
}

.inputError{
  color: red
}

#supportFormClearAllButton{
  float: right;
  border-radius: 12px;
  font-size: 80%;
  cursor: pointer;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
}

#subjectCharLeft{
  color: gray;
  font-style: italic;
}

#supportUploadFiles{
  background-color: #0088ff;
  color: white;
}

#supportUploadFiles:hover {
  box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24),0 17px 50px 0 rgba(0,0,0,0.19);
}

.buttonRaw {
  border: none;
  padding: 5px 8px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  margin: 4px 2px;
  cursor: pointer;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
}

#planTypeDisplay{
  display: grid;
  grid-template-columns: auto auto;
}

#planTypeDetails{
  float: right;
}

#pricingOptionsGrid{
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: auto;
  grid-template-areas: "pricingOptionsText pricingOptionsDropdown";
}

#pricingOptionsText{
  grid-area: pricingOptionsText;
  text-align: right;
  margin-top: 7px;
  margin-right: 3px;
  font-weight: bold;
}

#pricingOptionsDropdown{
  grid-area: pricingOptionsDropdown;
}

#profilePricingPlanRow{
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 30% 70%;
}

#planTypeUpgradeButton{
  margin-top: -10px;
}

.carousel{
  display: grid;
  grid-template-columns: 5% 90% 5%;
  grid-template-rows: auto 20px;
  grid-template-areas: "carouselLeft carouselContent carouselRight"
  "carouselLeft carouselIndicator carouselRight";
}

.carouselLeft{
  grid-area: carouselLeft;
  position: relative;
}

.carouselLeft:hover{
  background-color: rgba(128,128,128, 0.1);
}

.carouselContent{
  grid-area: carouselContent;

}

.carouselRight{
  grid-area: carouselRight;
  position: relative;
}

.carouselRight:hover{
  background-color: rgba(128,128,128, 0.1);
}

.carouselRight > div{
  float: right;
  padding-left: 5%;
  /*font-size: 250%;*/
}

.carouselIndicator{
  grid-area: carouselIndicator;
  /*margin: 0 auto;*/
  width: 100%;
  bottom: 0;
  position: absolute;
  text-align: center;
}

.carouselIndicator > div{
  text-align: center;
  padding-top: 50px;
}

#carouselLeftArrow{
  position: absolute;
  top: 50%;
  text-align: right;
}

#carouselRightArrow{
  position: absolute;
  top: 50%;
}

.featureModalSlide{
  /*border: 1px solid lightgrey;*/

}

.apiFeatures{
  margin-top: 150px;
  margin-bottom: 150px;
}

.featureModalHeader{
  text-align: center;
  /*font-size: 30px;*/
  font-size: 300%;
  font-family: museo sans rounded, sans-serif;
  display: block;
  /*text-shadow: 1px 1px 2px black;*/
  margin-bottom: 25px;
}

.featureModalSubHeader{
  /*text-shadow: 1px 1px 2px black;*/
  font-size: 20px;
  font-weight: 600;
}

.logoGrid{
  font-family: museo sans rounded, sans-serif;
  margin-top: 50px;
  margin-bottom: 100px;
}

.logoGrid > h1{
  font-size: 300%;
  text-align: center;
  margin-bottom: 50px;
  /*animation-name: fadeInUpCustom;*/
  /*animation-duration: 5s;*/
}

.logoGrid > div{
  position: absolute;
  float: right;
}

.logoGrid > div span{
  display: block;
  padding-left: 250px;
  font-size: 190%;
}

.logoGrid > img{
  padding-left: 200px;
  padding-right: 200px;
  /*display: grid;*/
  /*grid-template-columns: 33% 33% 34%;*/
  /*grid-template-rows: auto;*/
  /*grid-template-areas: "logo1 logo2 logo3";*/
  /*grid-column-gap: 10px;*/

}

.logoGrid image{
  text-align: center;
}

.logoGrid > span{
  position: absolute;
  /*top: 150px;*/
  /*top: 0;*/
  right: 250px;
  margin-top: 375px;
  padding: 15px 30px 15px 30px;
  border-radius: 15px;
  font-size: 200%;
  font-weight: bold;
  border: 1px solid lightgrey;
  background: linear-gradient(30deg, rgb(252,252,200) 5%, rgb(0,128,0) 92%);
  color: white;
}

.gridLogo1{
  grid-area: logo1;
}

.gridLogo2{
  grid-area: logo2;
}

.gridLogo3{
  grid-area: logo3;
}

.gridLogo1, .gridLogo2, .gridLogo3{
  text-align: center;
}


.featureModalSimpleAPIExampleTable{
  margin-bottom: 25px;
  display: grid;
  grid-template-columns: 25% 75%;
  grid-column-gap: 25px;
}


.featureModalSimpleAPIExampleTableValues, .featureModalSimpleAPIExampleTableRequest{
  border: 1px solid lightgrey;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.featureModalSimpleAPIExampleTableRequest{
  width: 100%;
}

#featureTable th{
  background-color: whitesmoke;
}

#featureTable td, th{
  border: 1px solid lightgrey;
  padding: 7px;
}

#featureTable{
  margin-top: 10px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.centerTable{
  margin-left: auto;
  margin-right: auto;
}

#apiFeatureModalURLBox{
  border: 1px solid lightgray;
  border-radius: 5px;
  width: 100%;
  background-color: #203354;
  color: white;
  padding: 10px;
  font-size: 15px;
}

#flashingQuery{
  animation: UPGRADE-PRIVATE-BLINK 2s infinite;
  font-weight: bold;
}

/*@keyframes UPGRADE-PRIVATE-BLINK{*/
/*0%		{ background-color: #c4ffc4;}*/
/*25%		{ background-color: #89ff89;}*/
/*50%		{ background-color: #4eff4e;}*/
/*75%		{ background-color: #14ff14;}*/
/*100%	    { background-color: #00d800;}*/
/*}*/

@keyframes UPGRADE-PRIVATE-BLINK{
  0%		{ color: #c4ffc4;}
  25%		{ color: #00d800;}
  50%		{ color: #c4ffc4;}
  75%		{ color: #00d800;}
  100%	    { color: #c4ffc4;}
}

#featureModalCodeOuterDiv{
  margin-bottom: 50px;
}

#featureModalAPIWave{
  /*position: absolute;*/
}

#featureModalAPICode{
  /*position: absolute;*/
}

.featureCTAHeroGrid{
  display: grid;
  grid-template-columns: 20% 60% 20%;
  grid-template-rows: auto;
  grid-template-areas: "featureCTAHeroGridCol1 featureCTAHeroGridCol2 featureCTAHeroGridCol3";
  /*height: 300px;*/
}

.featureCTAHeroGridCol1{
  grid-area: featureCTAHeroGridCol1;
  min-height: 0;
}

.featureCTAHeroGridCol2{
  grid-area: featureCTAHeroGridCol2;
  padding-top: 20px;
}

.featureCTAHeroGridCol3{
  grid-area: featureCTAHeroGridCol3;
  min-height: 0;
}

.featureCTAHero{
  background: url("./InfoPages/HowItWorks/Features/Blob1.svg") no-repeat;
}

.needAuthModalBody{
  background: url("./Authentication/UserPages/Data.svg") no-repeat;
}

.featureCTAHeroText{
  padding-top: 30px;
  text-align: center;
  padding-bottom: 30px;
}

.featureCTASignUp{
  text-align: center;
  /*margin-top: 60px;*/
  /*margin-bottom: 80px;*/
  z-index: 999;
  /*position: absolute;*/
}

.featureCTAKnowMore{
  text-align: center;
  padding-top: 50px;
  font-size: 200%;
  bottom: 0;
}

#featureCTASignUpButton{
  padding-left: 100px;
  padding-right: 100px;
  cursor: pointer;
}

#ctaLeftGrid{
  display: grid;
  grid-template-rows: 25% 25% 25% 25%;
  grid-template-columns: 25% 25% 25% 25%;
  grid-template-areas: "ctaLeft1 ctaLeft2 ctaLeft2 ctaLeft3"
  "ctaLeft4 ctaLeft5 ctaLeft6 ctaLeft7"
  "ctaLeft8 ctaLeft8 ctaLeft9 ctaLeft10"
  "ctaLeft11 ctaLeft12 ctaLeft13 ctaLeft14";
  height: 100%;
}

#ctaLeft2{
  background-color: #00d084;
  border-radius: 0 0 100% 100%;
  height: 50%;
}

#ctaLeft8{
  background-color: #00d084;
  border-radius: 0 100% 100% 0;
  width: 60%;
}

#ctaRightGrid{
  display: grid;
  grid-template-rows: 25% 25% 25% 25%;
  grid-template-columns: 25% 25% 25% 25%;
  grid-template-areas: "ctaRight1 ctaRight2 ctaRight3 ctaRight4"
  "ctaRight5 ctaRight6 ctaRight6 ctaRight7"
  "ctaRight8 ctaRight9 ctaRight10 ctaRight11"
  "ctaRight12 ctaRight13 ctaRight13 ctaRight14";
  height: 100%;
}
#ctaRight13{
  background-color: #00d084;
  border-radius: 100% 100% 0 0;
  /*height: 50%;*/
  bottom: 0;
}

#ctaRight7{
  background-color: #00d084;
  border-radius: 100% 0 0 100%;
  /*width: 60%;*/
}

#crudModalGrid{
  margin-top: 150px;
  margin-bottom: 150px;
  padding-left: 200px;
  padding-right: 200px;
  display: grid;
  grid-template-columns: 40% 20% 40%;
}

#crudModalCreateDiv, #crudModalUpdateDiv, #crudModalDeleteDiv, #crudModalReadDiv{
  padding: 30px;
}

#crudModalCreateDiv{
  background: url("./InfoPages/HowItWorks/Features/BlobCreate.svg") no-repeat;
}

#crudModalUpdateDiv{
  background: url("./InfoPages/HowItWorks/Features/BlobUpdate.svg") no-repeat;
  background-position: center;
}

.rightTable{
  margin-left: auto;
}

.featureModalReadJSONTable{
  margin-top: 10px;
  margin-left: auto;
  font-size: 90%;
  background-color: #11ab73ff;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  color: white;
  font-weight: bold;

}

.featureModalReadJSONTableTopRowCell{
  padding-top: 25px;
}

.featureModalReadJSONTableBottomRowCell{
  padding-bottom: 25px;
}

.featureModalReadJSONTableLeftCell{
  padding-left: 10px;
}

.featureModalReadJSONTableRightCell{
  padding-right: 10px;
}

#crudModalGridMiddleColumn{
  position: relative;
}

#crudCenter{
  position: absolute;
  top: 35%;
}

#crudCenter > h1{
  text-align: center;
}

.crudCenterIcon{
  text-align: center;
  display: block;
  color: orangered;
}


#crudCenterTextAndArrows{
  display: grid;
  grid-template-columns: 10% auto 10%;
  /*position: relative;*/
}

.crudCenterSideArrows{
  position: relative;
}

.crudCenterSideArrowsIcon{
  top: 40%;
  position: absolute;
}

.crudCenterText{
  text-align: center;
  font-size: 300%;
  font-family: museo sans rounded, sans-serif;
}

.featuresIndex{
  min-height: 100vh;
}

.featuresBlock{
  padding-top: 50px;
  padding-bottom: 50px;
}

.featuresPageFeatureTableLeft{
  display: grid;
  grid-template-columns: 30% 70%;
  grid-template-rows: auto;
  grid-template-areas: "featuresPageFeatureTableMenu featuresPageFeatureTableDisplay";
}

.featuresPageFeatureTableRight{
  display: grid;
  grid-template-columns: 70% 30%;
  grid-template-rows: auto;
  grid-template-areas: "featuresPageFeatureTableDisplay featuresPageFeatureTableMenu";
  margin: 0px 175px 0px 175px;
}

.featuresPageFeatureTableMenu{
  grid-area: featuresPageFeatureTableMenu;
  border-right: 1px solid lightgrey;
  padding: 20px;
}

.featuresPageFeatureTableDisplay{
  grid-area: featuresPageFeatureTableDisplay;
  padding: 20px;
}

.featuresPageFeatureTableLeft, .featuresPageFeatureTableRight{
  border: 1px solid lightgrey;
  border-radius: 10px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
}

.rounded-div-border{
  border: 1px solid lightgrey;
  border-radius: 10px;
}

.click-hover-div{
  cursor: pointer;
}

.click-hover-div:hover{
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
}

.featuresPageCrudBox{
  text-align: center;
  background-color: whitesmoke;
}

.simpleInterfaceBox{
  /*margin-bottom: 50px;*/
}

.simpleInterfaceBox h1{
  font-size: 450%;
}

.landingIcon{
  /*box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24),0 17px 50px 0 rgba(0,0,0,0.19);*/
}

.simpleInterfaceBoxBody{
  /*margin-top: 25px;*/
  /*bottom: 0;*/
  /*position: absolute;*/
  margin-bottom: 10px;
  padding-left: 5px;
  padding-right: 5px;
  text-align: center;
  font-size: 150%;
}

#exitModalFooterDiv{
  margin-bottom: 5px;
  /*padding-top: 10px;*/
  display: grid;
  grid-template-columns: 40% 60%;
  border-bottom: 1px solid lightgrey;
}

#exitModalFooterUndoToAction{
  /*float: left;*/
  /*bottom: 0;*/
  /*position: absolute;*/
}

#exitModalFooterUndoToAction > h6{
  /*top: 0;*/
  /*bottom: 0;*/
  /*position: absolute;*/
  /*padding-top: 15px;*/
}

#exitModalFooterLeaveTour{
  /*float: right;*/
  text-align: right;
}

#exitModalFooterLeaveTour > label{
  cursor: pointer;
}

#exitModalFooterLeaveTour > h6{
  color: red;
}

#actionModalBody{
  margin-top: 10px;
}

#actionModalBodyToolbar{
  display: grid;
  grid-template-columns: 50% 50%;
}

#actionModalBodyToolbarButtons{
  text-align: right;
}

#actionModalBodyToolbarButtons span{
  display: block;
}

/*.app *:not(.tutorialHighlighted){*/
  /*filter: blur(3px);*/
/*}*/

.tutorialHighlighted{
  /*background-color: whitesmoke;*/
  z-index: 500;
  position: relative;
  box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24),0 17px 50px 0 rgba(0,0,0,0.19);
  background-color: whitesmoke;
  /*opacity: 1;*/
}

.tutorialHighlighted1{
  z-index: 501;
}

.actionIndex{
  background-color: #203354;
  color: white;
  box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24),0 17px 50px 0 rgba(0,0,0,0.19);
  padding: 10px;
  border-radius: 10px;

}

.howDoesThisWork{
  text-align: center;
  margin: 125px 50px 0px 50px;
  font-family: museo sans rounded, "sans-serif";
}

.landingCTASignUp{
  text-align: center;
  margin-top: -2px;
}

#landingCTASignUpButton{
  cursor: pointer;
  background-color: green;
  color: white;
  padding: 15px 75px 15px 75px;
  font-size: 175%;
  font-weight: 400;
  /*box-shadow: 0 12px 16px 0 ,0 17px 50px 0;*/
  border-radius: 40px;
  transition: all 0.5s;
}

#landingCTASignUpButton span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

#landingCTASignUpButton span:after {
  content: '\00bb';
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

#landingCTASignUpButton:hover span {
  padding-right: 25px;
}

#landingCTASignUpButton:hover span:after {
  opacity: 1;
  right: 0;
}

.pricingHeroText{
  font-family: museo sans rounded, sans-serif;
  font-weight: bold;
  text-align: left;
}

.pricingPageImageOuter{
  background: url("LandingPage/Illustrations/MakeItRain.svg") no-repeat;
  /*height: 80%;*/
  width: 100%;
  /*padding-bottom: -20px;*/
  background-position: center;
}

.pricingPageImage{
  visibility: hidden;
  padding-bottom: 15px;
}

@media only screen and (max-width: 600px){
  .pricingHeroText{
    text-align: center;
    padding-bottom: 20px;
  }

  .pricingPageImageOuter{
    display: none;
  }

  /*.faqSummary{*/
    /*display: none;*/
  /*}*/

  .pricingBoxDisplay{
    display: none;
  }

  .pricingBox{
    margin-bottom: 25px;
  }
}

@media only screen and (min-width: 600px){
  .summaryMobileID{
    display: none;
  }
}

.pricingHaveMoreQuestions{
  background: url("./InfoPages/HowItWorks/Features/Blob1.svg") no-repeat;
  height: 250px;
  background-position: right top;
  background-size: 125%;
}

.pricingHaveMoreQuestionsText{
  text-align: center;
  padding-top: 15%;
}

.pricingDots > div > div > hr{
  background-color: lightgrey;
}

#featuresIndexHero{
  margin-top: 50px;
  background-size: 100% auto;
  background-repeat: no-repeat;
  position: relative;
}

#featuresIndexHeroButton{
  bottom: 75px;
  left: 150px;
  position: absolute;
  /*z-index: 150;*/
  /*padding-top: 10%;*/
}
#featuresIndexHero > img {
  /*position: relative;*/
  /*z-index: 125;*/
}

#featuresIndexHeroText{
  position: absolute;
  top:25px;
  width: 50%;
  text-align: center;
  font-family: museo sans rounded, sans-serif;
}

#featuresIndexHeroText > h1{
  /*font-size: 200%;*/
  font-weight: 900;
}

@keyframes fadeInUpCustom {
  from {opacity: 0; transform: translate3d(0, 100%, 0)}
  to {opacity: 1; transform: none}
}

.featureAPI{
  background: url("../src/InfoPages/Features/FeaturesAPI.svg") no-repeat;
  background-position: 50%;
  margin-top: 150px;
  padding: 0 200px 0 200px;
  margin-bottom: 150px;
}

.dashboardFeatureSubHeading{
  font-family: museo sans rounded, sans-serif;
  font-size: 150%;
  font-weight: bold;
}


.navLink:hover{
  /*font-weight: bold;*/
  border-bottom: 5px solid #6FBB98;
  margin-bottom: 3px;
}

.importNavLink:hover{
  /*font-weight: bold;*/
  border-bottom: 5px solid #E27837;
  margin-bottom: 3px;
}

.utilitiesNavLink:hover{
  /*font-weight: bold;*/
  border-bottom: 5px solid #5499F8;
  margin-bottom: 3px;
}

.navbarAuthDiv{
  /*background-color: rgba(0, 0, 0, 0.5);*/
  background-color: white;
  border: 1px solid #F0F0F0;
  /*box-shadow: 0px 2px 10px rgba(2, 34, 19, 0.06);*/
}

.docsHeader{
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 8px;
}

.apiIndexMenuItem, .apiIndexMenuItemSelected, .importIndexMenuItem, .importIndexMenuItemSelected, .commonIndexMenuItem, .commonIndexMenuItemSelected, .utilitiesIndexMenuItem, .utilitiesIndexMenuItemSelected{
  text-align: center;
  font-size: 12px;
  padding: 8px;
  font-weight: 700;
  border-radius: 8px;
  cursor: pointer;
  /*font-weight: 700;*/
}

.utilitiesIndexMenuItem{
  color: #5499F8;
}

.apiIndexMenuItem{
  color: #6FBB98;
}

.importIndexMenuItem{
  color: #E27837;
}

.commonIndexMenuItem{
  color: #9696A0
}

.apiIndexMenuItem, .importIndexMenuItem, .commonIndexMenuItem, .utilitiesIndexMenuItem{
  border: 1px solid #F0F0F0;
}

.apiIndexMenuItem:hover, .importIndexMenuItem:hover, .commonIndexMenuItem:hover, .utilitiesIndexMenuItem:hover{
  box-shadow: 0px 2px 10px rgba(2, 34, 19, 0.06);
  color: white;
}

.utilitiesIndexMenuItem:hover{
  background-color: #5499F8;
}

.apiIndexMenuItem:hover{
  background-color: #6FBB98;
}

.importIndexMenuItem:hover{
  background-color: #E27837;
}

.commonIndexMenuItem:hover{
  background-color: #9696A0;
}

.apiIndexMenuItemSelected, .importIndexMenuItemSelected, .commonIndexMenuItemSelected, .utilitiesIndexMenuItemSelected{
  color: white;
}

.utilitiesIndexMenuItemSelected{
  background-color: #5499F8;
}

.apiIndexMenuItemSelected{
  background-color: #6FBB98;
}

.importIndexMenuItemSelected{
  background-color: #E27837;
}

.commonIndexMenuItemSelected{
  background-color: #9696A0;
}

.navbarMobileButton{
  cursor: pointer;
}

.mobileFeaturesDiv{
  /*font-family: museo sans rounded, "sans-serif";*/
  /*margin: 50px 0 50px 0;*/
  /*padding-bottom: 30px;*/
  /*border-bottom: 1px solid lightgrey;*/
}

.museoHeader{
  text-align: center;
  font-family: museo sans rounded, "sans-serif";
  padding-bottom: 50px;
}

@media only screen and (max-width: 600px){
  .app{
    margin: 0 30px 0 30px;
  }
}

.useCaseCard{
  /*font-family: museo sans rounded, "sans-serif";*/
  padding: 16px 24px 16px 24px;
  /*box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);*/
  margin-top: 24px;
  border-radius: 4px;
  /*background-color: whitesmoke;*/
  border: 1px solid #F0F0F0;
  position: relative;
  /*margin-left: 40px;*/
  /*cursor: pointer;*/
}

.useCaseCard:hover{
  box-shadow: 0 8px 32px 0 rgba(0,0,0,0.2);
}

.useCaseCard img{
  /*height: 50%;*/
  width: 40%;
  margin: 0 auto;
}

.useCaseCardBg{
  background-color: orangered;
  padding-top: 20px;
  border-radius: 10px;
  padding-bottom: -40px;
  position: relative;
  /*position: absolute;*/
}

.useCaseCardBg1{
  background-image: linear-gradient(to right, green, greenyellow, yellow);
}

.useCaseCardBg2{
  background-image: linear-gradient(to bottom, greenyellow, yellow, green);
}

.useCaseCardBg3{
  background-image: linear-gradient(to bottom right, yellowgreen, greenyellow, green);
}

.featuresButtonDiv{
  /*height: 660px;*/
  background: url("../src/SharedComponents/LandingUseCases/FooterBg.svg") no-repeat;
  background-size: cover;
}

.learnFeaturesLandingBtn{
  text-align: center;
}

.postParagraphText{
  font-size: 16px;
  margin-bottom: 20px;
}

.whatIsAnAPIButton{
  margin: 15px 0 0 25px;
  /*margin-left: 20px;*/
  /*display: block;*/
  /*margin-bottom: -25px;*/
}

.whatIsAnAPIButton > a {
  padding: 15px;
  /*font-size: 125%;*/
  box-shadow: 0 4px 8px rgba(0,0,0,0.19), 0 0px 4px rgba(0,0,0,0.23);
  border-radius: 25px;
  background-image:  linear-gradient(to right, yellowgreen, green);
  color: whitesmoke;
  font-weight: bold;
}

.apiSpreadsheetsText{
  font-weight: bold;
  /*color: green;*/
}

.googleEmail{
  font-weight: bold;
  /*color: cornflowerblue;*/
}

.caseStudyIndex{
  min-height: 100vh;
  margin: 50px;
  margin-bottom: 450px;
}

.columnNamesTutorial{
  border: 1px solid lightgrey;
  border-radius: 10px;
  padding: 20px;
  text-align: left;
  margin-top: 10px;
  margin-bottom: 20px;
  background-color: whitesmoke;
}

.fixColumnNames{
  display: grid;
  grid-template-columns: 50% 50%;
}

.fixColumnNames > h3{
  text-align: center;
  padding-bottom: 10px;
}

.fixColumnNamesTableHeaderRow td{
  border: 1px solid #F0F0F0;
  padding: 7px;
  font-weight: bold;
  background-color: #F4FBF3;
  text-align: center;
  color: #6FBB98;
}

.fixColumnNamesTableBodyRow td {
  text-align: center;
  padding: 10px;
  border: 1px solid #F0F0F0;
  background-color: white;
  color: #5E645E;
}

.fixColumnNamesTable{
  margin: 0 auto;
}

.datasetIndex{
  min-height: 100vh;
}

.datasetRow{
  border-top: 1px solid lightgrey;
  width: 75%;
  margin: 0 auto;
  /*padding-top: 15px;*/
  /*padding-bottom: 15px;*/
}

.datasetRowBody{
  margin-top: 50px;
  margin-bottom: 150px;
}

.datasetDownloadButton{
  background-color: green;
  height: 100%;
  padding: 10px 8px 8px 10px;
  text-align: center;
  color: whitesmoke;
  cursor: pointer;
}

.datasetDownloadButton > span{
  font-size: 450%;
}

.datasetDownloadButton > p {
  font-size: 120%;
  font-weight: bold;
}

.datasetDownloadButton:hover{
  background-color: #00bb00;
}

.datasetRow:hover{
  background-color: whitesmoke;
}

.datasetRowLink{
  text-decoration: none;
  color: black;
}

.datasetRowLink:hover{
  text-decoration: none;
  color: blue;
}

.details-picture{
  height: 150px;
  width: 150px;
}

.predictorHeaderCell, .predictorCell{
  background-color: lightgrey;
  /*color: white;*/
}

.detailMetadataTag{
  /*font-weight: 900;*/
  /*font-size: 130%;*/
}

.detailMetadataValue{
  font-size: 115%;
}

.privacyAndTerms p{
  margin-bottom: 125px;
}

.promoIndex{
  min-height: 100vh;
}

.promoPricing{
  padding-bottom: 20px;
  /*padding-top: 20px;*/
  /*padding-right: 50px;*/
}

.promoPricingBoxes{
  border: 1px solid lightgrey;
  border-radius: 15px;
  padding: 10px;
  text-align: center;
}

.promoCurrency{
  text-align: center;
  padding-bottom: 25px;
}

.promoPaymentIndex{
  text-align: center;
  margin: 50px auto 500px auto;
  width: 50%;
  border: 1px solid lightgrey;
  padding: 15px;
  border-radius: 15px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  /*min-height: 100vh;*/
}

@media only screen and (max-width: 600px) {
  .promoPaymentIndex {
    text-align: center;
    margin: 50px auto 500px auto;
    width: 100%;
    border: 1px solid lightgrey;
    padding: 15px;
    border-radius: 15px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    /*min-height: 100vh;*/
  }
}

.promoTimerCountdown{
  text-align: center;
  border: 1px solid lightgrey;
  border-radius: 50px;
  padding: 15px;
  background: linear-gradient(90deg, rgba(233,93,6,1) 0%, rgba(208,54,15,1) 35%, rgba(255,171,0,1) 100%);
  color: whitesmoke;
}

.promoCountdownLabel{
  font-size: 16px;
}

.blogHero{
  padding-top: 100px;
  padding-bottom: 100px;
  text-align: center;
  background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(130,214,130,1) 100%);
  /*color: white;*/
}

.blogHero > h1{
  font-size: 450%;
  font-weight: 300;
}

.tutorialsPreviewDiv{
  /*margin-right: 50px;*/
  /*margin-bottom: 250px;*/
  text-align: center;
  margin-bottom: 80px;
}

@media only screen and (min-width: 600px) {
  .tutorialsDisplayIndex {
    width: 50%;
    margin: 0 auto;
    margin-bottom: 150px;
    margin-top: 50px;
  }
}

@media only screen and (max-width: 600px) {
  .tutorialsDisplayIndex {
    margin: 5px 10px 150px 10px;
  }
}
/*.guidePost{*/
  /*position: relative;*/
/*}*/
.tutorialShare{
  border: 1px solid lightgrey;
  border-radius: 15px;
  padding: 25px;
  /*height: 100px;*/
  /*width: 50px;*/
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  float: left;
  margin: 10px 0 0 50px;
  position: fixed;
  text-align: center;
}

.privateToggleTutorialSpan{
  border: 1px solid lightgrey;
  border-radius: 25px;
  padding: 15px 15px 15px 15px;
}

.commentSection{
  margin-top: 75px;
  border-top: 1px solid lightgrey;
  padding-top: 25px;
}

.emailUnsubscribeIndex{
  min-height: 100vh;
  text-align: center;
  margin-top: 100px;
}

.emailUnsubscribeIndex > h4{
  margin-bottom: 50px;
}

#importEditTableContainer{
  width: 100%;
  overflow-y: auto;
  /*margin-top: 30px;*/
  padding-bottom: 10px;
}

#importEditTable{
  width: auto;
}

#importEditTableContainer, #importEditTable{
  transform:rotateX(180deg);
  -moz-transform:rotateX(180deg); /* Mozilla */
  -webkit-transform:rotateX(180deg); /* Safari and Chrome */
  -ms-transform:rotateX(180deg); /* IE 9+ */
  -o-transform:rotateX(180deg); /* Opera */
}

.awsS3AccountRow:hover, .s3BucketSelectionRow:hover{
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  cursor: pointer;
}

.s3BucketSelectionRow{
  border: 1px solid lightgrey;
  padding: 5px;
  border-radius: 4px;
}

.s3BucketSelectionRow > span{
  font-size: 16px;
  /*font-weight: bold;*/
}

.guidePostScrollButton{
  display: none;
  position: fixed;
  bottom: 20px;
  right: 100px;
  z-index: 99;
  font-size: 18px;
  border: none;
  outline: none;
  background-color: green;
  color: white;
  cursor: pointer;
  padding: 15px;
  border-radius: 50px;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}

@media only screen and (min-width: 600px) {
  .uploadGrid{
    display: grid;
    grid-template-columns: 20% 20% 20% 20% 20%;
  }
}

.viewDatabaseButton{
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  padding: 25px;
  margin-bottom: 25px;
  cursor: pointer;
}

.viewDatabaseButton:hover{
  box-shadow: 0 12px 24px 0 rgba(0,0,0,0.2);
  background-color: lightgrey;
}

.query-row{
  cursor: pointer;
}

.pageHeader, .tinyPageHeader{
  width: 100%;
}

.pageHeader h3, .tinyPageHeader h3{
  color: #161E16;
  font-size: 20px;
  font-weight: 700;
}

.pageHeader h5, .tinyPageHeader h5{
  color: #5E645E;
  font-size: 14px;
  font-weight: 400;
}

.tinyPageHeader > div:nth-of-type(1){
  padding:30px 30px 0px 30px;
}

.tinyPageHeader > div:nth-of-type(2){
  padding:10px 30px 30px 30px;
}

.pageHeader > div:nth-of-type(1){
  padding:30px 60px 0px 60px;
}

.pageHeader > div:nth-of-type(2){
  padding:10px 60px 30px 60px;
}

.filesTabFileSummaryRow:hover{
  background-color: whitesmoke;
}

.backHeaderIconSpan{
  font-size: 12px;
  margin-right: 10px;
  padding-top: -5px;
}

.backHeaderIcon{
  transition: transform .2s; /* Animation */
  cursor: pointer;
}

.backHeaderIcon:hover{
  transform: scale(1.3);
}

.pageHeaderButton, .pageHeaderSegment{
  background: #FFFFFF;
  border-radius: 4px;
  padding: 6px 10px 7px;
  height: 31px;
  border: none;
  outline: none;
}

.pageHeaderButton{
  cursor: pointer;
}

.pageHeaderButtonLoader{
  /*width: 100%;*/
  /*height: 31px;*/
  /*padding: 6px 10px 7px;*/
}

.filesIDIndexHeaderContainer{
  display: flex;
  align-items: center;
  /*justify-content: center;*/
}

.filesIDIndexHeaderImage{
  max-width: 100%;
  flex-basis: 40%;
  margin-left: 8px;
}

.filesIDIndexHeaderText{

}

.noStyleBtn{
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  /*cursor: pointer;*/
  outline: inherit;
}

.menuDisplayGrid{
  display: grid;
  grid-template-columns: 292px auto;
  column-gap: 24px;
}

.filesIDDisplayIndex{
  display: grid;
  grid-template-columns: 292px auto;
  column-gap: 24px;
}

.filesIDDisplayMenu, .menuDiv{
  padding: 16px;
  border: 1px solid #F0F0F0;
  border-radius: 4px;
}

.menuDiv{
  padding: 16px;
  border: 1px solid #F0F0F0;
  border-radius: 4px;
}

.flashLabel{
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    background-color: #fbe0df;
  }
}

.filesIDDisplay{
  /*padding: 16px;*/
}

.menuSegment, .menuItem, .importerMenuSegment{
  display: grid;
  grid-template-columns: auto 1fr auto;
  padding: 12px 4px 12px 4px;
  font-family: Lato, sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #9696A0;
  transition: transform .2s; /* Animation */
}

.menuItem{
  cursor: pointer;
}

.menuSegment:hover, .importerMenuSegment:hover{
  cursor: pointer;
  transform: scale(1.02);
}

.menuSegment:hover{
  color: #6FBB98;
}

.importerMenuSegment:hover{
  color: #E27837;
}

#menuSegmentSelected, #importerMenuSegmentSelected{
  transform: scale(1.02);
}

#menuSegmentSelected{
  color: #6FBB98;
}

#importerMenuSegmentSelected{
  color: #E27837;
}

.menuButton{
  padding: 8px 8px 8px 12px;
  border: 1px solid #F0F0F0;
  border-radius: 4px;
  display: grid;
  grid-template-columns: auto 1fr auto;
  background-color: white;
  color: #5E645E;
}

.privateButton{
  padding: 8px 8px 2px 12px;
  border: 1px solid #F0F0F0;
  border-radius: 4px;
  display: grid;
  grid-template-columns: auto 1fr auto;
  background-color: white;
  color: #5E645E;
}

#menuButtonSelected{
  border: none;
  background-color: #F4FBF3;
  color: #6FBB98;
}

.menuButton:hover{
  background-color: #F4FBF3;
  color: #6FBB98;
  cursor: pointer;
}

.apiURLText{
  padding: 8px 6px 8px 12px;
  background: #F0F0F0;
  border-radius: 4px;
  font-size: 14px;
  font-family: 'Lato', sans-serif;
  color: #5E645E;
}

.callToActionBtn{
  padding: 8px 12px 8px 12px;
  border-radius: 4px;
  font-family: Lato, sans-serif;
  outline: none;
  border: none;
  cursor: pointer;
  font-weight: 700;
  font-size: 14px;
}

.callToActionBtn > div {
  display: grid;
  grid-template-columns: auto auto;
}

.callToActionBtn > div > div:nth-of-type(1){
  text-align: left;
}

.callToActionBtn > div > div:nth-of-type(2){
  text-align: right;
}

.lightCTABtn{
  border: 1px solid #6FBB98;
  background: #F4FBF3;
  color: #6FBB98;
}

.lightCTABtnOrange{
  border: 1px solid #E27837;
  background: #F4FBF3;
  color: #E27837;
}

.darkCTABtn{
  background: #6FBB98;
  border-radius: 4px;
  color: white;
}

.fileInformationTableHeader{
  display: grid;
  grid-template-columns: 35% 15% 18% 22% 10%;
}

.teamFilesRowHeader{
  display: grid;
  grid-template-columns: 45% 25% 15% 15%;
}

.importUploadFilesSegmentDiv{
  display: grid;
  grid-template-columns: 35% 20% 27% 15% 3%;
}

.fileErrorRow{
  display: grid;
  grid-template-columns: 35% 15% 50%;
}

.databaseSegmentRow{
  display: grid;
  grid-template-columns: 30% 15% 15% 25% 12% 3%;

}

.fileRowFileSource{
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 10px;
}

.descGenericText{
  font-family: Lato, sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #5E645E;
}

.fixColumnNamesReuploadBtn{
  padding: 8px 12px;
  background: #6FBB98;
  outline: none;
  border-radius: 4px;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: #FFFFFF;
  text-align: center;
  cursor: pointer;
  border: none;
  width: 100%;
}

.fixColumnNamesEditBtn{
  padding: 8px 12px;
  background: white;
  border: 1px solid #6FBB98;
  outline: none;
  border-radius: 4px;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: #FFFFFF;
  text-align: center;
  cursor: pointer;
  width: 100%;
}

.fixColumnNamesBtnRow{
  display: grid;
  grid-template-columns: 35% 35% 1fr;
  column-gap: 10px;
}

.bannerAlert {
  min-height: 80px;
  background: #FFFFFF;
  padding: 16px;
  border-radius: 4px;
  display: grid;
  grid-template-columns: auto 1fr auto;
}

.bannerIcon{
  margin-right: 10px;
}

.iconBtn{
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
}

.iconBtn i {
  /*vertical-align: middle;*/
}

.filesIDDisplayBox{
  border: 1px solid #F0F0F0;
  border-radius: 4px;
  padding: 16px 16px 32px 16px;
}

.displayBox{
  border: 1px solid #F0F0F0;
  border-radius: 4px;
  padding: 16px;
}

.inputGray{
  background: #F0F0F0;
  border-radius: 4px;
  padding: 6px 8px;
  border: none;
  outline: none;
  color: #161E16;
}

.themeGreenBtn{
  background-color: #6FBB98;
  color: white;
}

.fileIDHeading{
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
}

.teamSegmentRow{
  display: grid;
  grid-template-columns: auto 1fr auto auto auto;
  grid-column-gap: 25px;
}

.teamPageSegmentRow{
  display: grid;
  grid-template-columns: auto 6fr 2fr 2fr 2fr 1fr 2fr 1fr;
  grid-column-gap: 15px;
  border-bottom: 1px solid #F0F0F0;
  padding-bottom: 10px;
  padding-top: 10px;
}

.databaseAPIRows{
  display: grid;
  grid-template-columns: 5% 35% 20% 25% 10% 5%;
  border-bottom: 1px solid #F0F0F0;
  padding-bottom: 10px;
  padding-top: 10px;
}

.importSegmentRow{
  display: grid;
  grid-template-columns: 30% 15% 35% 15%;
  /*grid-column-gap: 15px;*/
  border-bottom: 1px solid #F0F0F0;
  padding-bottom: 10px;
  padding-top: 10px;
}


.invitesSentRow{
  display: grid;
  grid-template-columns: 6fr 3fr 4fr 1fr;
  grid-column-gap: 10px;
}

.inputGray::placeholder{
  color: #BCBCC2;
}

.pricingTableDiv{
  display: grid;
  grid-template-columns: auto 1fr 1fr 1fr 1fr;
}

.pricingFAQHeading{
  font-weight: 700;
  font-size: 20px;
  color: #161E16;
  margin-bottom: 24px;
}

.pricingFAQSection{
  margin-bottom: 50px;
}

.docsSection{
  margin-bottom: 40px;
}

.docsList{
  padding-left: 20px;
  margin-bottom: 12px;
  margin-top: -8px;
}

.docsList p{
  margin-bottom: 2px;
}

.urlBoxHeader{
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #343b43;
  color: lightgrey;
  border-radius: 8px 8px 0 0;
  font-size: 90%;
}

.urlBoxLink{
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #677685;
  color: white;
  border-radius: 0 0 8px 8px;
}

#uploadFilesSampleTableContainer{
  width: 100%;
  overflow-y: auto;
  /*margin-top: 24px;*/
  padding-bottom: 12px;
}

#uploadFilesSampleTable{
  /*overflow-x: scroll;*/
  width: auto;
}

#uploadFilesSampleTableContainer, #uploadFilesSampleTable{
  transform:rotateX(180deg);
  -moz-transform:rotateX(180deg); /* Mozilla */
  -webkit-transform:rotateX(180deg); /* Safari and Chrome */
  -ms-transform:rotateX(180deg); /* IE 9+ */
  -o-transform:rotateX(180deg); /* Opera */
}